<script>
import { SuawMainContent, SuawSection, SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton, SuawLink } from "@suaw/suaw-component-library";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import * as UserApi from "../../api.js";

export default {
  name: "ResetPassword",
  components: { SuawMainContent, SuawSection, SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton, SuawLink },
  data() {
    return {
      activeForm: "forgot-password",
      forgotPasswordForm: {
        email: ""
      },
      verificationForm: {
        token: ""
      },
      resetPasswordForm: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    forgotPasswordForm: {
      email: {
        required,
        email
      }
    },
    verificationForm: {
      token: { required }
    },
    resetPasswordForm: {
      password: { required, minLength: minLength(8), maxLength: maxLength(200) },
      confirmPassword: { required, sameAsPassword: sameAs("password") }
    }
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.resetPasswordForm.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.resetPasswordForm.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    }
  },
  mounted() {
    const { token: refreshToken } = this.$route.params;
    if (refreshToken) {
      this.loginByToken(refreshToken);
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    uppercaseToken() {
      this.verificationForm.token = this.verificationForm.token.toUpperCase();
    },
    redirectToSignIn() {
      this.$router.push({ name: "SignIn" });
    },
    async requestPasswordReset() {
      const result = await UserApi.requestPasswordReset(this.forgotPasswordForm.email);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async loginByToken(token) {
      const result = await UserApi.loginByToken(token);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      // begin rare case of doing more stuff inside the helper method before returning its result
      const { accessToken, user } = result.result.value || {};
      this.$auth.refreshAuthUser({ accessToken, user });
      this.activeForm = "reset-password";
      // end rare case of doing more stuff inside the helper method before returning its result
      return result;
    },
    async resetPassword() {
      const result = await UserApi.resetPassword(this.$auth.user.id, this.resetPasswordForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onPasswordResetRequested() {
      this.$v.forgotPasswordForm.$touch();
      if (this.$v.forgotPasswordForm.$invalid) {
        this.$root.$emit("universal-error-message", "request password reset form invalid");
      } else {
        const requestPasswordResetResult = await this.requestPasswordReset();
        if (requestPasswordResetResult.success) {
          this.activeForm = "verification";
        }
      }
    },
    async onEmailVerification() {
      this.$v.verificationForm.$touch();
      if (this.$v.verificationForm.$invalid) {
        this.$root.$emit("universal-error-message", "verification form invalid");
      } else {
        await this.loginByToken(this.verificationForm.token);
      }
    },
    async onPasswordReset() {
      this.$v.resetPasswordForm.$touch();
      if (this.$v.resetPasswordForm.$invalid) {
        this.$root.$emit("universal-error-message", "password reset invalid");
      } else {
        const resetPasswordResult = await this.resetPassword();
        if (resetPasswordResult.success) {
          await this.$auth.logout({ redirectLocation: { name: "none" } });
          this.activeForm = "success";
        }
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawSection section-style="border">
      <SuawForm v-if="activeForm === 'forgot-password'" @submit="onPasswordResetRequested">
        <template #form>
          <SuawHeading level="3" content="Forgot your password?" alignment="center" />
          <SuawTextInput
            id="email-pw-reset"
            v-model="forgotPasswordForm.email"
            type="email"
            placeholder="Email Address"
            is-required
            no-label
            :state="formFieldState($v, 'forgotPasswordForm', 'email')"
            :error-message="validateErrors($v.forgotPasswordForm.email, 'Email')"
            @blur="$v.forgotPasswordForm.email.$touch()"
          />
          <SuawParagraph size="small" text="Enter your email address and we’ll send you an email with a link to reset your password." alignment="left" />
          <SuawButton size="large" type="primary" button-text="Send Password Reset" />
          <SuawLink to="/sign-in" alignment="center">Return to login page</SuawLink>
        </template>
      </SuawForm>

      <SuawForm v-if="activeForm === 'verification'" @submit="onEmailVerification">
        <template #form>
          <SuawHeading level="3" content="Verify Your Email" alignment="center" />
          <SuawTextInput
            id="token-pw-reset"
            v-model="verificationForm.token"
            type="text"
            placeholder="Enter Code"
            is-required
            no-label
            :state="formFieldState($v, 'verificationForm', 'token')"
            :error-message="validateErrors($v.verificationForm.token, 'Verification code')"
            @input="uppercaseToken"
            @blur="$v.verificationForm.token.$touch()"
          />
          <SuawParagraph
            size="small"
            text="Reset your password by clicking the verification link we've sent to your email. Or, enter the code from your verification email above."
            alignment="left"
          />
          <SuawButton size="large" type="primary" button-text="Verify Code" />
        </template>
      </SuawForm>

      <SuawForm v-if="activeForm === 'reset-password'" @submit="onPasswordReset">
        <template #form>
          <SuawHeading level="3" content="Reset Your Password" alignment="center" />
          <SuawTextInput
            id="password-pw-reset"
            v-model="resetPasswordForm.password"
            type="password"
            placeholder="Enter Password"
            is-required
            no-label
            :state="formFieldState($v, 'resetPasswordForm', 'password')"
            :error-message="validateErrors($v.resetPasswordForm.password, 'Password')"
            @blur="$v.resetPasswordForm.password.$touch()"
          />
          <SuawTextInput
            id="password-confirm-pw-reset"
            v-model="resetPasswordForm.confirmPassword"
            type="password"
            placeholder="Confirm Password"
            is-required
            no-label
            :state="formFieldState($v, 'resetPasswordForm', 'confirmPassword')"
            :error-message="validateConfirmPasswordError"
            @blur="$v.resetPasswordForm.confirmPassword.$touch()"
          />
          <SuawParagraph size="small" text="You have been verified! You can now reset your password." alignment="left" />
          <SuawButton size="large" type="primary" button-text="Reset Password" />
        </template>
      </SuawForm>

      <SuawForm v-if="activeForm === 'success'" @submit="redirectToSignIn">
        <template #form>
          <SuawHeading level="3" content="Your password is reset!" alignment="center" />
          <SuawParagraph size="small" text="You have been logged out and must log back in to continue." alignment="left" />
          <SuawButton size="large" type="primary" button-text="Log In" />
        </template>
      </SuawForm>
    </SuawSection>
  </SuawMainContent>
</template>
